// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex;
  height: 100vh; 
}

.left-side {
  width: 40%;
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.left-side h2 {
  margin-bottom: 20px;
}

.centered-image {
  margin-top: 80px;
  width: 450px;
  height: 120px;
  
}

.right-side {
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-with-image {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.button-image {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.button {
  width: 120px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  color:white;
  border: 1px solid #ccc;
  background-color: #46A3FF;
  border-radius: 10px; 
  cursor: pointer;
}

.button:hover {
  background-color: #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;;AAEf;;AAEA;EACE,UAAU;EACV,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".app {\n  display: flex;\n  height: 100vh; \n}\n\n.left-side {\n  width: 40%;\n  background-color: #f0f0f0;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.left-side h2 {\n  margin-bottom: 20px;\n}\n\n.centered-image {\n  margin-top: 80px;\n  width: 450px;\n  height: 120px;\n  \n}\n\n.right-side {\n  width: 60%;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.button-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.button-with-image {\n  display: flex;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.button-image {\n  width: 100px;\n  height: 100px;\n  margin-right: 20px;\n}\n\n.button {\n  width: 120px;\n  height: 40px;\n  padding: 10px;\n  font-size: 16px;\n  color:white;\n  border: 1px solid #ccc;\n  background-color: #46A3FF;\n  border-radius: 10px; \n  cursor: pointer;\n}\n\n.button:hover {\n  background-color: #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

body {
    margin: 0;
    font-family: Times;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: Times;
  }
  `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,mCAAmC;IACnC,kCAAkC;EACpC;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["\n\nbody {\n    margin: 0;\n    font-family: Times;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n  \n  code {\n    font-family: Times;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instagram-page {
    display: flex;
    height: 100vh;
}

.instagram-left-side {
    width: 50%;
    background-color: #4267b2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-image {
    width: 100%;
    height: 100%;
}

.instagram-right-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.instagram-right-side h2 {
    margin-bottom: 20px;
}

.user-input {
    width: 60%;
    padding: 10px;
    margin-bottom: 25px;
    font-size: 16px;
}

.authenticate-button {
    width: 120px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    color: white;
    border: 1px solid #ccc;
    background-color: #1877f2;
    border-radius: 10px;
    cursor: pointer;
}

.authenticate-button:hover {
    background-color: #365899;
}
`, "",{"version":3,"sources":["webpack://./src/InstagramPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".instagram-page {\n    display: flex;\n    height: 100vh;\n}\n\n.instagram-left-side {\n    width: 50%;\n    background-color: #4267b2;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.full-image {\n    width: 100%;\n    height: 100%;\n}\n\n.instagram-right-side {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    padding: 20px;\n}\n\n.instagram-right-side h2 {\n    margin-bottom: 20px;\n}\n\n.user-input {\n    width: 60%;\n    padding: 10px;\n    margin-bottom: 25px;\n    font-size: 16px;\n}\n\n.authenticate-button {\n    width: 120px;\n    height: 40px;\n    padding: 10px;\n    font-size: 16px;\n    color: white;\n    border: 1px solid #ccc;\n    background-color: #1877f2;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n.authenticate-button:hover {\n    background-color: #365899;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoginPage.css */
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-image {
    width: 230px;
    height: auto;
    margin-bottom: 20px;
}

.password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-toggle-button {
    position: absolute;
    width: 52px;
    height: 30px;
    cursor: pointer;
    left: 340px;
}


.login-page input {
    width: 300px;
    margin: 10px;
    padding: 10px;
    font-size: 16px;
}

.login-button {
    width: 500px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #FFC627;
    color:black;
    border: 1px solid #ccc;
    border-radius: 10px; 
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/LoginPage.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,WAAW;AACf;;;AAGA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":["/* LoginPage.css */\r\n.login-page {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n}\r\n\r\n.login-image {\r\n    width: 230px;\r\n    height: auto;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.password-container {\r\n    position: relative;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.password-toggle-button {\r\n    position: absolute;\r\n    width: 52px;\r\n    height: 30px;\r\n    cursor: pointer;\r\n    left: 340px;\r\n}\r\n\r\n\r\n.login-page input {\r\n    width: 300px;\r\n    margin: 10px;\r\n    padding: 10px;\r\n    font-size: 16px;\r\n}\r\n\r\n.login-button {\r\n    width: 500px;\r\n    padding: 10px 20px;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    background-color: #FFC627;\r\n    color:black;\r\n    border: 1px solid #ccc;\r\n    border-radius: 10px; \r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

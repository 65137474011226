// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reddit-page {
    display: flex;
    height: 100vh;
    
}

.reddit-left-side {
    width: 50%;
    background-color: 	#FF8040;
    display: flex;
    align-items: center; 
    justify-content: center; 
}

.full-image {
    /* width: 300px;
    height: 150px; */
    width: 100%;
    height: 100%;

}

.reddit-right-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.reddit-right-side h2 {
    margin-bottom: 20px;
}

.user-input {
    width: 60%;
    padding: 10px;
    margin-bottom: 25px;
    font-size: 16px;
}

.download-button {
    width: 120px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    color:white;
    border: 1px solid #ccc;
    background-color: #46A3FF;
    border-radius: 10px; 
    cursor: pointer;
}

.download-button:hover {
    background-color: #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/RedditPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;;AAEjB;;AAEA;IACI,UAAU;IACV,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;oBACgB;IAChB,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".reddit-page {\n    display: flex;\n    height: 100vh;\n    \n}\n\n.reddit-left-side {\n    width: 50%;\n    background-color: \t#FF8040;\n    display: flex;\n    align-items: center; \n    justify-content: center; \n}\n\n.full-image {\n    /* width: 300px;\n    height: 150px; */\n    width: 100%;\n    height: 100%;\n\n}\n\n.reddit-right-side {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    padding: 20px;\n}\n\n.reddit-right-side h2 {\n    margin-bottom: 20px;\n}\n\n.user-input {\n    width: 60%;\n    padding: 10px;\n    margin-bottom: 25px;\n    font-size: 16px;\n}\n\n.download-button {\n    width: 120px;\n    height: 40px;\n    padding: 10px;\n    font-size: 16px;\n    color:white;\n    border: 1px solid #ccc;\n    background-color: #46A3FF;\n    border-radius: 10px; \n    cursor: pointer;\n}\n\n.download-button:hover {\n    background-color: #e0e0e0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
